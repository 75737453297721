import React from "react"
import chunk from "lodash/chunk"
import classnames from "classnames"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import GridItem from "../../components/gridItem"
import CatNav from "../../components/catnav"
import useWindowWidth from "../../utils/useWindowWidth"

const DevelopmentsTypePage = ({ pageContext }) => {
    const windowWidth = useWindowWidth()
    const data = pageContext?.data
    const basicPath = pageContext?.basicPath
    const cats = []
    for (const cat of data?.wpChildren?.nodes) {
        cats.push({
            name: cat?.name,
            slug: `${basicPath}${cat?.slug}/`,
        })
    }
    if (cats.length > 1) {
        cats.unshift({ name: "Show All", slug: `${basicPath}` })
    }
    let projects =
        pageContext?.child !== undefined
            ? pageContext?.child?.projects
            : data?.projects
    const projectNodes = (projects && projects.nodes) || []
    // add additional grid item here
    // const tests = [
    //     { title: "Testing with longer text here", slug: "#" },
    //     { title: "Test", slug: "#" },
    // ]
    const tests = []
    const projectChunks = chunk([...projectNodes, ...tests], 4)
    const countGridSize = count => {
        const gutterWidth = (count - 1) * 13
        let size
        if (windowWidth >= 768) {
            size = (windowWidth - gutterWidth) / 4
        } else {
            size = windowWidth
        }
        return size
    }
    return (
        <Layout activeMenuId={3}>
            <Seo
                title={data?.name}
                description={data?.seo?.metaDesc}
                img={data?.seo?.opengraphImage?.mediaItemUrl}
                imgHeight={data?.seo?.opengraphImage?.mediaDetails?.height}
                imgWidth={data?.seo?.opengraphImage?.mediaDetails?.width}
            />
            <PageHeader
                hasUnderline
                title={data?.name}
                subTitle={data?.description}
                image={data?.acfPropertyType?.banner?.mediaItemUrl}
                breadcrumbs={[
                    { label: `Developments / ${data?.name}`, link: null },
                ]}
            />
            <div className="container mx-auto">
                <div className="text-center mb-16">
                    <CatNav
                        catsList={cats}
                        activeCat={pageContext?.currentPath}
                    />
                </div>
            </div>
            <div className="mb-4">
                {projectChunks.map((row, r) => {
                    const gridSize = countGridSize(row.length)
                    return (
                        <div
                            key={`row-${r}`}
                            className="flex flex-col md:flex-row justify-center items-start mb-4"
                        >
                            {row.map((item, i) => {
                                const itemClass = classnames("mb-4 md:mb-0", {
                                    "md:ml-0 md:mr-2": i === 0,
                                    "md:ml-2 md:mr-0": i + 1 === row.length,
                                    "md:ml-2 md:mr-2":
                                        i > 0 && i + 1 < row.length,
                                })
                                return (
                                    <div
                                        key={i}
                                        style={{
                                            width: gridSize,
                                        }}
                                        className={itemClass}
                                    >
                                        <GridItem
                                            bgImage={
                                                item?.featuredImage?.node
                                                    ?.mediaItemUrl
                                            }
                                            label={item?.title}
                                            link={`${basicPath}${item?.slug}`}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </Layout>
    )
}

export default DevelopmentsTypePage
