import { useState, useEffect } from "react"

export default function useWindowWidth() {
  const [width, setWidth] = useState()

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      setWidth(width)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return width
}
