import React, { useRef, useState, useEffect } from "react"
import classnames from "classnames"
import * as styles from "../styles/blocks.module.css"
import { Link } from "gatsby"

const CatNav = ({ catsList, activeCat }) => {
    const navRef = useRef()
    const [isOpened, setIsOpened] = useState(false)
    const listClass = classnames({
        [styles.catOpened]: isOpened,
    })
    const toggleOpened = () => setIsOpened(!isOpened)
    useEffect(() => {
        setIsOpened(false)
    }, [activeCat])
    useEffect(() => {
        const checkIfClickOutside = e => {
            if (
                isOpened &&
                navRef.current &&
                !navRef.current.contains(e.target)
            ) {
                setIsOpened(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickOutside)
        }
    }, [isOpened])
    return (
        <div className="relative" ref={navRef}>
            <button
                className={`relative py-4 lg:hidden ${styles.catNavToggle}`}
                onClick={toggleOpened}
            >
                {catsList.find(item => item?.slug === activeCat)?.name}
            </button>
            <ul className={`${styles.catNav} ${listClass}`}>
                {catsList.map((c, i) => {
                    return (
                        <li key={`c-${i}`}>
                            <button
                                style={{
                                    fontWeight:
                                        c.slug === activeCat
                                            ? "bold"
                                            : "normal",
                                    color:
                                        c.slug === activeCat
                                            ? "#0E4372"
                                            : "#58595b",
                                }}
                            >
                                <Link to={c?.slug}> {c.name} </Link>
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default CatNav
